<template>
  <div>
    <header
      :class="[
        $style.header,
        layoutCustomProps.color === 'navy' && $style.headerNavy,
      ]"
    >
      <h1>
        <img
          :src="`${
            layoutCustomProps.color === undefined
              ? '/img/top/logo.svg'
              : '/img/top/logo-white.svg'
          } `"
          width="207"
          height="19"
          alt="WARC AGENT"
          :class="$style.headerImg"
        />
      </h1>
      <div :class="$style.headerButton">
        <ButtonsTopHeaderButton
          text="転職サポートを登録する"
          type="link"
          :path="fullUrl('/registration')"
        />
      </div>
    </header>
    <slot />
    <footer :class="$style.footer">
      <div :class="$style.footerContents">
        <div :class="$style.footerContentsBox">
          <span :class="$style.footerContentsBoxTitle">運営会社</span>
          <span :class="$style.footerContentsBoxText">株式会社WARC</span>
        </div>
        <div :class="$style.footerContentsBox">
          <span :class="$style.footerContentsBoxTitle">所在地</span>
          <span :class="$style.footerContentsBoxText">
            〒141-0021
            <br />
            東京都品川区上大崎2-25-2 新目黒東急ビル9F
          </span>
        </div>
        <div :class="$style.footerContentsBox">
          <span :class="$style.footerContentsBoxTitle">資本金</span>
          <span :class="$style.footerContentsBoxText">
            800,000,000円（資本準備金含む）
          </span>
        </div>
        <div :class="$style.footerContentsBox">
          <span :class="$style.footerContentsBoxTitle">代表取締役</span>
          <span :class="$style.footerContentsBoxText">山本 彰彦</span>
        </div>
        <div :class="$style.footerContentsBox">
          <span :class="$style.footerContentsBoxTitle">設立年月</span>
          <span :class="$style.footerContentsBoxText">2017年5月</span>
        </div>
        <div :class="$style.footerContentsBox">
          <span :class="$style.footerContentsBoxTitle">従業員数</span>
          <span :class="$style.footerContentsBoxText">90人</span>
        </div>
        <div :class="$style.footerContentsBox">
          <span :class="$style.footerContentsBoxTitle">{{
            isPc ? '有料職業紹介許可番号' : '有料職業紹\n介許可番号'
          }}</span>
          <span :class="$style.footerContentsBoxText">13-ユ-308828</span>
        </div>
        <div :class="[$style.footerContentsBox, $style.copyrightBox]">
          <span :class="$style.footerContentsBoxTitle"></span>
          <span :class="[$style.footerContentsBoxText, $style.copyright]"
            >&copy; 2024 WARC.inc</span
          >
        </div>
      </div>
    </footer>
  </div>
</template>

<script setup lang="ts">
const layoutCustomProps = useAttrs();
const isPc = ref(false);

const checkWindowWidth = () => {
  isPc.value = screen.width > PcViewWidth;
};

onMounted(() => {
  checkWindowWidth();
  window.addEventListener('resize', checkWindowWidth);
});
</script>

<style lang="scss" module>
.header {
  display: flex;
  align-items: center;
  background-color: colors(base-light);
  padding: 0 mp(16);
  height: 6.4rem;
  margin: 0 auto;

  @include sp {
    width: 100%;
    padding: mp(8) mp(12);
  }
}

.headerNavy {
  background-color: colors(lp-007-dark-blue);
}

.headerImg {
  @include sp {
    width: 15.5rem;
    height: 1.4rem;
  }
}

.headerButton {
  margin-left: auto;
  width: 18.9rem;

  @include sp {
    width: 9rem;
  }
}

.footer {
  background-color: colors(base-hover);
  padding: mp(40) mp(20) mp(20) mp(20);
  width: 100%;
  margin: 0 auto;

  @include sp {
    padding: mp(32) mp(32) mp(128) mp(32);
  }
}

.footerContents {
  display: flex;
  flex-direction: column;
  gap: mp(16);
  width: 47.4rem;
  margin: 0 auto;

  @include sp {
    width: 100%;
  }
}

.footerContentsBox {
  display: flex;
  gap: mp(40);

  @include sp {
    gap: 2.8rem;
  }
}

.footerContentsBoxTitle {
  width: 140px;
  text-align: right;
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 1.96rem;
  color: colors(base-light);

  @include sp {
    width: 7.1rem;
    text-align: right;
    white-space: pre-wrap;
  }
}

.footerContentsBoxText {
  font-size: 1.4rem;
  font-weight: 300;
  line-height: 1.96rem;
  color: colors(base-light);

  @include sp {
    width: calc(100% - 9.9rem);
  }
}

.copyrightBox {
  @include sp {
    display: flex;
    flex-direction: row-reverse;
  }
}

.copyright {
  color: colors(base-light);
  font-size: mp(12);
  font-weight: 400;
  line-height: 1.2rem;
  margin-top: mp(4);

  @include sp {
    width: 100%;
    margin-top: mp(64);
  }
}
</style>
